<template>
  <div
    class="team-params-root"
  >
    <div class="section-title team-params-title">
      {{ teamName }}
    </div>
    <div
      class="team-params-content"
    >
      <div
        id="team-params-items"
        class="team-params-items"
      >
        <div
          v-for="(param, index) in teamParamsFiltered"
          :key="param.key"
          :class="[
            'team-param-single',
            { 'is-auto-mode': autoMode },
          ]"
          :id="`team-param-single-${index}`"
        >
          <div class="team-param-single-header">
            <div class="short-name">
              {{ param.shortName }}
            </div>
            <Tooltip
              :text="param.longName"
              right
              :left="isTooltipPositionLeft(
                'team-params-items',
                `team-param-single-${index}`
              )?.isLeft"
            >
              <Icon
                class="global-param-single-name-icon"
                name="info"
              />
            </Tooltip>
          </div>
          <NumberInput
            class="global-param-single-input"
            :class="{ 'is-error': param.isError }"
            :model-value="param.value"
            :is-error="param.isError"
            :error-message="param.errorMessage"
            :accept-negative="(param.minValue < 0)"
            :readonly="isInferActive || autoMode"
            :has-arrows="true"
            :number-of-decimals="param.numberOfDecimals || 3"
            @update:modelValue="onTeamParamChange($event, param)"
            @onKeyUp="onTeamParamIncrease(param)"
            @onKeyDown="onTeamParamDecrease(param)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep, filter, includes } from 'lodash';
import NumberInput from '@/components/common/NumberInput';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import { getTeamParamsKeysByTeamAndSport, isTooltipPositionLeft, getBasketballGlobalParamsModelType } from '@/components/player-setup/params-helper';

export default {
  emits: ['toggleShowAllTeamParameters'],
  components: {
    NumberInput,
    Tooltip,
    Icon,
  },
  props: {
    isShowAllTeamParametersActive: {
      type: Boolean,
      default: false,
    },
    teamLabel: {
      type: String,
      default: '',
    },
    teamName: {
      type: String,
      default: '',
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const sportId = computed(() => store.getters.playerSetupData?.sportId);
    const globalParamsModelType = computed(() => getBasketballGlobalParamsModelType(store.getters.playerSetupData?.competitionType));
    const teamParamsKeys = computed(() => getTeamParamsKeysByTeamAndSport(props.teamLabel, sportId.value, globalParamsModelType.value));
    const allTeamParams = computed(() => filter(
      store.getters.playerSetupGameParams, (param) => includes(teamParamsKeys.value, param.key),
    ));
    const teamParamsFiltered = computed(() => {
      if (props.isShowAllTeamParametersActive) return allTeamParams.value;
      return filter(allTeamParams.value, (param) => param.isMain);
    });
    const isInferActive = computed(() => store.getters.playerSetupInferActive);

    const onTeamParamChange = (value, param) => {
      const updatedParam = cloneDeep(param);
      if (value) {
        const paramValue = parseFloat(value);
        const isError = paramValue < param?.minValue || paramValue > param?.maxValue;
        updatedParam.value = paramValue;
        updatedParam.isError = isError;
      } else {
        updatedParam.isError = true;
        updatedParam.value = null;
      }
      store.dispatch('updatePlayerSetupGameParams', updatedParam);
    };
    const onTeamParamIncrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value + updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue <= updatedParam.maxValue) {
        updatedParam.value = updatedValue;
        store.dispatch('updatePlayerSetupGameParams', updatedParam);
      }
    };

    const onTeamParamDecrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value - updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue >= updatedParam.minValue) {
        updatedParam.value = updatedValue;
        store.dispatch('updatePlayerSetupGameParams', updatedParam);
      }
    };

    return {
      teamParamsFiltered,
      onTeamParamChange,
      onTeamParamIncrease,
      onTeamParamDecrease,
      isTooltipPositionLeft,
      isInferActive,
    };
  },
};
</script>

<style lang="scss">
.team-params-root {
  padding: 16px;
  .team-params-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .team-params-content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    .team-params-items {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      .team-param-single {
        width: 67px;
        &.is-error {
          width: 90px;
        }
        &.is-auto-mode {
          position: relative;
          z-index: 100;
        }
        .team-param-single-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .short-name {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $gray800;
            text-transform: uppercase;
            font-size: 10px;
            margin-bottom: 2px;
            font-weight: 600;
          }
          .team-param-single-name-icon {
            cursor: pointer;
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
        .team-param-single-input {
          width: 100%;
        }
      }
    }
  }
}
</style>
